<template>
	<div class="record_box">
		<div class="record">
			<div class="title">{{$t('card_shop_v.title')}}</div>
			<div class="a_body">
				<!-- <div class="tip">
					<el-icon style="color: #ff8900; font-size: 20px; margin-right: 7px"><Warning /></el-icon>
					<span>
						卡片适用于大多数线上消费场景（ChatGPT、CloudFlare、Tiktok、MidJourney、Wechat/AliPay、Telegram、
						Facebook、GODADDY...），以下分类根据卡片所擅长应用进行区分。
					</span>
				</div> -->
				<div v-if="true" class="card_box">
					<div
						class="item"
						v-for="(item, index) in cardList"
						:key="index"
						@mouseover="showIn18(index)"
						@mouseleave="hideIn18(index)"
					>
						<!-- <div class="lg_box"> -->
						<img src="../../assets/card/detail_card2.png" alt="" />
						<button @click="toUrl(item)" class="button" v-show="item.cardFlag">{{$t('card_shop_v.button')}}</button>
						<!-- </div> -->
						<div class="content_box">
							<div class="number">Virtual</div>
							<div class="cardNo">{{ item.bin }}</div>
							<div class="fee">{{$t('card_shop_v.fee1')}}{{ state.feeInfo.virtualCardRechargeFeeRate }}%</div>
							<div class="fee">{{$t('card_shop_v.fee2')}}</div>
							<div class="cardType">
								{{ item.network }}
							</div>
						</div>
					</div>
				</div>
				<el-empty v-else class="empty" description="无记录，我要申请卡片" image-size="150">
					<!-- image="../../assets/card/empty.png" -->
					<el-button>{{$t('card_shop_v.button')}}</el-button>
				</el-empty>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import QrcodeVue from 'qrcode.vue';
import { Warning } from '@element-plus/icons-vue';
import { getFeeInfoApi, availableCardListApi } from '@/api/index';

const router = useRouter();
let state = reactive({
	feeInfo: {},
});

let cardList = ref([]);

// 加载ui
const loadingInstance1 = ElLoading.service({ fullscreen: true });
// api 请求
availableCardListApi()
	.then((res) => {
		// 关闭加载
		loadingInstance1.close();
		if (res.code == 200) {
			cardList.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
		loadingInstance1.close();
	});
// api 请求
getFeeInfoApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			state.feeInfo = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
const toUrl = (item) => {
	router.push({
		name: 'virtualDetail',
		params: {
			itemInfo: JSON.stringify(item),
		},
	});
};
const showIn18 = (i) => {
	cardList.value[i].cardFlag = true;
};

const hideIn18 = (i) => {
	cardList.value[i].cardFlag = false;
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.record_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.record {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.title {
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				padding-left: 25px;
				display: flex;
				align-items: center;
			}
			.a_body {
				// height: 100;
				height: 1200px;
				padding: 40px 20px;
				overflow: auto;
				.tip {
					padding: 17px 20px;
					width: 1252px;
					height: 93px;
					background: #fff9e4;
					border-radius: 5px;
					border: 1px solid #ffd897;
					font-size: 21px;
					color: #333333;
					display: flex;
					align-items: center;
					margin-bottom: 40px;
				}
				.card_box {
					display: flex;
					width: 100%;
					// height: 100%;
					flex-wrap: wrap;
					justify-content: flex-start;

					.item {
						margin: 0 30px 30px 0;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						.content_box {
							position: absolute;
							z-index: 2;
							width: 100%;
							height: 100%;
							padding: 27px 33px;
							color: #ffffff;
							.number {
								font-size: 27px;
								margin-bottom: 18px;
							}
							.cardNo {
								font-size: 29px;
								font-weight: 600;
								margin-bottom: 18px;
							}
							.fee {
								margin-bottom: 12px;
								font-size: 17px;
							}
							.cardType {
								font-size: 19px;
							}
						}
						img {
							width: 400px;
							height: 253px;
							border-radius: 20px;
						}
						.button {
							position: absolute;
							z-index: 99;
							width: 133px;
							height: 53px;
							background: #ffffff;
							border-radius: 33px;
							font-weight: 600;
							font-size: 21px;
							color: #333333;
							border: 0;
						}
					}
				}
				.empty {
					display: flex;
					align-items: center;
					justify-content: center;
					button {
						width: 142px;
						height: 36px;
						background: #19d079;
						border-radius: 4px;
						font-weight: 600;
						font-size: 16px;
						color: #ffffff;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
}
</style>

<style lang="scss">
.record_box {
	.el-table th.el-table__cell {
		background: #f6f8fc !important;
		color: #4a4a4a;
	}
}
</style>
