<template>
	<div class="record_box">
		<div class="record">
			<div class="title">卡片商店-实体卡</div>
			<div class="a_body">
				<!-- <div class="tip">
					<el-icon style="color:#FF8900;font-size:20px;margin-right:7px;"><Warning /></el-icon>
					<span>
					卡片适用于大多数线上消费场景（ChatGPT、CloudFlare、Tiktok、MidJourney、Wechat/AliPay、Telegram、
					Facebook、GODADDY...），以下分类根据卡片所擅长应用进行区分。
					</span>
				</div> -->
				<div v-if="true" class="card_box">
					<div
						class="item"
						v-for="(item, index) in cardList"
						:key="index"
						@mouseover="showIn18(index)"
						@mouseleave="hideIn18(index)"
					>
						<img src="../../assets/shop/shop1.png" alt="" />
						<button @click="toUrl('/admin/shop/physicalDetail')" class="button" v-show="item.cardFlag">
							立即申请
						</button>
					</div>
				</div>
				<el-empty v-else class="empty" description="无记录，我要申请卡片" image-size="150">
					<!-- image="../../assets/card/empty.png" -->
					<el-button>去申请</el-button>
				</el-empty>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import QrcodeVue from 'qrcode.vue';
import { Warning } from '@element-plus/icons-vue';


const router = useRouter();
let state = reactive({
	input1: 'GJDKIOQMME',
	input2: 'https://www.coin.com/zh-hant/assets/coin',
	dialogTableVisible: false,
});

const tableData = [
	{
		date: '2016-05-03',
		name: 'Tom',
		state: 'California',
		zip: 'CA 90036',
	},
	{
		date: '2016-05-02',
		name: 'Tom',
		state: 'California',
		zip: 'CA 90036',
	},
];
let cardList = reactive([
	{
		number: '2016-1205-1203',
		date: '2016-05-02',
		type: '1',
		cardFlag: false,
	},
	{
		number: '2016-1205-1203',
		date: '2016-05-02',
		type: '2',
		cardFlag: false,
	},
]);
// let cardFlag = ref(false);

const toUrl = (item) => {
	console.log(item);
	router.push(item);
};
const showIn18 = (i) => {
	cardList[i].cardFlag = true;
	// console.log(cardList[i].cardFlag, '111');
};

const hideIn18 = (i) => {
	cardList[i].cardFlag = false;
	// console.log(cardList[i].cardFlag, '222');
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.record_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.record {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.title {
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				padding-left: 25px;
				display: flex;
				align-items: center;
			}
			.a_body {
				// height: 100;
				height: 1200px;
				padding: 40px 20px;
				.tip {
					padding: 17px 20px;
					width: 1252px;
					height: 93px;
					background: #fff9e4;
					border-radius: 5px;
					border: 1px solid #ffd897;
					font-size: 21px;
					color: #333333;
					display: flex;
					align-items: center;
					margin-bottom: 40px;
				}
				.card_box {
					display: flex;
					width: 100%;
					// height: 100%;
					flex-wrap: wrap;
					justify-content: flex-start;

					.item {
						margin: 0 30px 30px 0;
						display: flex;
						align-items: center;
						justify-content: center;
						// position: relative;
						img {
							width: 400px;
							height: 253px;
							border-radius: 20px;
						}
						.button {
							position: absolute;
							z-index: 99;
							width: 133px;
							height: 53px;
							background: #ffffff;
							border-radius: 33px;
							font-weight: 600;
							font-size: 21px;
							color: #333333;
							border: 0;
						}
					}
				}
				.empty {
					display: flex;
					align-items: center;
					justify-content: center;
					button {
						width: 142px;
						height: 36px;
						background: #19d079;
						border-radius: 4px;
						font-weight: 600;
						font-size: 16px;
						color: #ffffff;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
}
</style>

<style lang="scss">
.record_box {
	.el-table th.el-table__cell {
		background: #f6f8fc !important;
		color: #4a4a4a;
	}
}
</style>
